import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { deleteCookie, getCookie } from '../helpers/cookieHelper';

export const getToken = () => {
    let token = getCookie('token');
    return token;
};

//  axio header setup
axios.defaults.baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? `https://pvr-api.valet2you.in`
        :  process.env.REACT_APP_NODE_ENV === 'qa'
        ? `https://pvr-api-qa.valet2you.in`
        : `https://pvr-api-dev.valet2you.in`;

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        const token = getToken();
        if (token) {
            config.headers.common['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        if (errorObject.status === 401) {
            deleteCookie('token');
            window.location.replace('/');
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export const loginUser = (data) => {
    return axios.post(`api/v1/login`, data);
};
export const logout = () => {
    return axios.get('api/v1/logout');
};
// menu managerment
export const getMenus = () => {
    return axios.get('/api/v1/menus?comprehensive=true');
};

export const createMenu = (payload, id) => {
    if (id) {
        payload.append('_method', 'PUT');
        return axios.post(`api/v1/menus/${id}`, payload);
    }
    return axios.post('/api/v1/menus', payload);
};

export const getCategories = (id) => {
    return axios.get(`api/v1/menus/categories/${id}?comprehensive=true`);
};
export const createCategory = (payload, id) => {
    if (id) {
        payload.append('_method', 'PUT');
        return axios.post(`api/v1/menus/categories/${id}`, payload);
    }
    return axios.post('api/v1/menus/categories', payload);
};

export const getItems = (id) => {
    return axios.get(`api/v1/menus/items/${id}?comprehensive=true`);
};
export const getAddons = (itemID, id) => {
    return axios.get(
        `api/v1/menus/addons/${itemID}?menu_sub_addon_id=${id}&comprehensive=true`
    );
};
export const getSubAddons = (id) => {
    return axios.get(`api/v1/menus/sub-addons/${id}`);
};

export const createItems = (payload, id) => {
    if (id) {
        payload.append('_method', 'PUT');
        return axios.post(`api/v1/menus/items/${id}`, payload);
    }
    return axios.post('api/v1/menus/items', payload);
};
export const createSubAddon = (payload, id) => {
    if (id) {
        payload.append('_method', 'PUT');
        return axios.post(`api/v1/menus/sub-addons/${id}`, payload);
    }
    return axios.post('api/v1/menus/sub-addons', payload);
};
export const createAddon = (payload, id) => {
    if (id) {
        payload.append('_method', 'PUT');
        return axios.post(`api/v1/menus/addons/${id}`, payload);
    }
    return axios.post('api/v1/menus/addons', payload);
};

export const toggleEnableDisable = (section, section_id) => {
    return axios.put(`api/v1/menus/toggle/${section}/${section_id}`);
};

export const getTaxes = (section, section_id) => {
    return axios.get(`api/v1/taxes`);
};

export const globalSearch = (str) => {
    return axios.get(`api/v1/search/full-menu/${str}`);
};

export const deleteMenu = (type, id) => {
    let url =
        type === 'menus' ? `api/v1/menus/${id}` : `api/v1/menus/${type}/${id}`;
    return axios.delete(url);
};

export const deleteSubAddon = (menu_item_id) => {
    return axios.delete(`api/v1/menus/sub-addons/${menu_item_id}`);
};
export const deleteAddon = (menu_item_id) => {
    return axios.delete(`api/v1/menus/addons/${menu_item_id}`);
};

export const updateConfig = () => {
    // return
};
export const getAllTerm = (enc_outlet_id) => {
    return axios.get(`api/v1/terms`);
};
export const createTerms = (body) => {
    return axios.post(`api/v1/terms`, body);
};
export const updateTerms = (id, body) => {
    return axios.post(`api/v1/terms/${id}`, body);
};
export const deleteTerms = (id, body) => {
    return axios.delete(`api/v1/terms/${id}`);
};
export const getProperties=()=>{
    return axios.get('api/v1/properties?is_paginated=0');
}
export const getOutlets=(params = {})=>{
    return axios.get('api/v1/outlets?is_paginated=0',{
        params: params
    });
}
export const getKiosks = (params = {}) => {
    return axios.get('api/v1/kiosk?is_paginated=0', {
        params: params
    });
};
export const getAnalytics=(payload)=>{
    return axios.post('api/v1/analytics',
        payload
    );
}