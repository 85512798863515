import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Card, Select } from 'antd';
import { Bar, Line } from 'react-chartjs-2';
import { itemLabels } from '../helpers/commonHelper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement, // Register the LineElement controller
    Title,
    Tooltip,
    Legend
);

const { Option } = Select;

const LeastSellingItem = ({ analyticsData, maxCount, setMaxCount }) => {
    if (!analyticsData || !analyticsData.leastSellingItems) return null;

    const leastSellingItems = analyticsData.leastSellingItems;

    const labels=itemLabels(leastSellingItems);

    const quantityData = leastSellingItems.map(item => item.sold_quantity);
    const revenueData = leastSellingItems.map(item => item.total_earning);

    const data = {
        labels,
        datasets: [
            {
                label: 'Quantity',
                data: quantityData,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                type: 'bar',
                yAxisID: 'y1', // Quantity axis
            },
            {
                label: 'Revenue (₹)',
                data: revenueData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                type: 'bar',
                yAxisID: 'y2', // Revenue axis
                pointRadius: 6,
                pointBorderWidth: 3,
                pointBackgroundColor: 'rgba(54, 162, 235, 1)',
                pointBorderColor: 'rgba(255, 255, 255, 1)',
                pointHoverRadius: 8,
                pointHoverBorderWidth: 4,
                tension: 0.5,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Item Name',
                    color: 'rgba(0, 0, 0, 0.87)',
                    font: { size: 16 },
                },
            },
            y1: {
                type: 'linear',
                position: 'left',
                title: {
                    display: true,
                    text: 'Quantity',
                    color: 'rgba(0, 0, 0, 0.87)',
                    font: { size: 16 },
                },
                beginAtZero: true,
            },
            y2: {
                type: 'linear',
                position: 'right',
                title: {
                    display: true,
                    text: 'Revenue (₹)',
                    color: 'rgba(0, 0, 0, 0.87)',
                    font: { size: 16 },
                },
                grid: { drawOnChartArea: false },
                beginAtZero: true,
                ticks: { stepSize: 100 },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                onClick: (e, legendItem) => {
                    const chart = e.chart;
                    const meta = chart.getDatasetMeta(legendItem.datasetIndex);
                    meta.hidden = !meta.hidden;
                    chart.update();
                },
            },
            tooltip: {
                callbacks: {
                    label: ({ dataIndex, dataset }) => {
                        const item = leastSellingItems[dataIndex];
                        return [
                            `Item: ${item.name}`,
                            dataset.label === 'Quantity'
                                ? `Sold Quantity: ${item.sold_quantity}`
                                : `Total Revenue: ₹${item.total_earning.toFixed(2)}`,
                        ];
                    },
                },
            },
        },
    };

    return (
        <Card
            title='Least Selling Items'
            extra={
                <Select
                    style={{ width: '100%', marginBottom: 16 }}
                    placeholder='Max Count'
                    onChange={setMaxCount}
                    value={maxCount}
                >
                    {[5, 10, 20, 30].map(value => (
                        <Option key={value} value={value}>{value}</Option>
                    ))}
                </Select>
            }
        >
            <div className='chart-container'>
                <Bar data={data} options={options} />
            </div>
        </Card>
    );
};

export default LeastSellingItem;
