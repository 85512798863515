import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Select,
    Input,
    SimpleGrid,
    Box,
    Switch,
    useToast,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { serialize } from 'object-to-formdata';
import { createSubAddon } from '../../services/apiService';
import { trimSpaceFromString } from '../../helpers/commonHelper';

const SubAddonModal = (props) => {
    const { isOpen, onClose, selectedSubAddon, selectedID, fetchSubAddons } =
        props;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const enabledFlag  = selectedSubAddon.enabled ?? 1;

    const [formData, setFormData] = useState({
        menu_item_id: selectedID.itemID || '',
        name: selectedSubAddon.name || '',
        priority: selectedSubAddon.priority || 0,
        enabled: Number(enabledFlag),
        select_type: selectedSubAddon.select_type || 'single',
        max_addon: selectedSubAddon.max_addon ?? 5,
        min_addon: selectedSubAddon.min_addon ?? 0,
    });
    const toast = useToast();

    const handleChange = (e) => {
        setError('');
        let value = null;
        const name = e.target.name;
        const type = e.target.type;
        if (type === 'checkbox') {
            value = e.target.checked;
            if (name === 'enabled' || name === 'is_recommended') {
                value = Number(value);
            }
        } else {
            value = e.target.value;
        }
        setFormData({ ...formData, [name]: value });
    };
    const saveClickHandler = async () => {
        try {
            setLoading(true);
            trimSpaceFromString(formData);
            const datatoSend = serialize(formData);
            const response = await createSubAddon(
                datatoSend,
                selectedSubAddon.id
            );
            if (response.status === 201 || response.status === 200) {
                onClose();
                fetchSubAddons(selectedID.itemID);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 422 || response.status === 503) {
                const message =
                    response.data.message ||
                    'Something went Wrong!! Please try again.';
                setError(message);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {selectedSubAddon.id ? 'Edit' : 'Add'} Customization
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={3}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            placeholder='Enter Customization Name'
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                            name='name'
                            type='text'
                        />
                    </FormControl>

                    <SimpleGrid columns={3} spacing={5}>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Priority</FormLabel>
                                <Input
                                    placeholder='Enter Priority'
                                    value={formData.priority}
                                    onChange={(e) => handleChange(e)}
                                    name='priority'
                                    type='number'
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    placeholder='Select option'
                                    value={formData.select_type}
                                    onChange={(e) => handleChange(e)}
                                    name='select_type'
                                >
                                    <option value='single'>
                                        Single Select
                                    </option>
                                    <option value='multi'>
                                        Multiple Select
                                    </option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Min Addon</FormLabel>
                                <Input
                                    placeholder='Enter Min Addon'
                                    value={formData.min_addon}
                                    onChange={(e) => handleChange(e)}
                                    name='min_addon'
                                    type='number'
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>

                    <SimpleGrid columns={1} spacing={5}>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>
                                    Maximum options that a guest can choose.
                                </FormLabel>
                                <Input
                                    placeholder='Enter Max Addon'
                                    value={formData.max_addon}
                                    onChange={(e) => handleChange(e)}
                                    name='max_addon'
                                    type='number'
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={5}>
                        <Box>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='0'>
                                    Item Enable/Disable
                                </FormLabel>
                                <Switch
                                    id='enable'
                                    type={'checkbox'}
                                    name='enabled'
                                    isChecked={Boolean(formData.enabled)}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>

                    {error && (
                        <p className='error-message'>
                            <InfoOutlineIcon /> {error}
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        colorScheme='gray'
                        variant='outline'
                        onClick={saveClickHandler}
                        ml={2}
                        disabled={loading}
                        isLoading={loading}
                        loadingText='Submitting'
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SubAddonModal;
