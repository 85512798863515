import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Select,
    Input,
    Textarea,
} from '@chakra-ui/react';
import { createTerms } from '../../services/apiService';
import { serialize } from 'object-to-formdata';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { trimSpaceFromString } from '../../helpers/commonHelper';

const NewConfigModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        model_type: 'kiosk',
        model_id: 2,
        type: '',
        content: '',
    });
    const [editorData, setEdtitorData] = useState('');
    const { isOpen, onClose } = props;
    const handleChange = (e) => {
        let value = null;
        const name = e.target.name;
        const type = e.target.type;
        if (type === 'checkbox') {
            value = e.target.checked;
        } else if (type === 'file') {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }
        setFormData({ ...formData, [name]: value });
    };
    const saveClickHandler = async () => {
        setLoading(true);
        try {
            if(formData.type === 'terms_and_conditions' || formData.type === 'privacy_policy') {
                formData['content']= editorData
            }
            trimSpaceFromString(formData);
            const datatoSend = serialize(formData);
            const response = await createTerms(datatoSend);
            console.log(response);
        } catch (error) {}
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add new config</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={3}>
                        <FormLabel>Modal Type</FormLabel>
                        <Select
                            value={formData.model_type}
                            onChange={(e) => handleChange(e)}
                            name='model_type'
                        >
                            <option value='kiosk'>kiosk</option>
                            <option value='outlet'>Outlet</option>
                            <option value='property'>Property</option>
                        </Select>
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Modal Type</FormLabel>
                        <Select
                            value={formData.type}
                            onChange={(e) => handleChange(e)}
                            name='type'
                        >
                            <option value='hero_banner'>hero_banner</option>
                            <option value='terms_and_conditions'>
                                terms_and_conditions
                            </option>
                            <option value='listing_slider_img'>
                                listing_slider_img
                            </option>
                            <option value='checkout_slider_img'>
                                checkout_slider_img
                            </option>
                            <option value='privacy_policy'>
                                privacy_policy
                            </option>
                        </Select>
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Image</FormLabel>
                        <Input
                            placeholder='Enter Image'
                            // value={formData.ima}
                            onChange={(e) => handleChange(e)}
                            name='content'
                            type='file'
                        />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Image</FormLabel>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        '|',
                                        'link',
                                        '|',
                                        'undo',
                                        'redo',
                                    ],
                                },
                            }}
                            data={editorData}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setEdtitorData(data);
                            }}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        colorScheme='gray'
                        variant='outline'
                        onClick={saveClickHandler}
                        ml={2}
                        disabled={loading}
                        isLoading={loading}
                        loadingText='Submitting'
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NewConfigModal;
