export const convertImageToBase64 = (fileToLoad, cb) => {
    var fileReader = new FileReader();

    fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        cb(srcData);
    };
    fileReader.readAsDataURL(fileToLoad);
};
export const debounce = (fn, delay = 300) => {
    let timer;
    return function () {
        let args = arguments;
        let _this = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(_this, args);
        }, delay);
    };
};


export const trimSpaceFromString = (dataSet = {})=>{
    for (const key in dataSet) {
        if (Object.hasOwnProperty.call(dataSet, key)) {
            const element = dataSet[key];
            if(typeof element === 'string') {
                dataSet[key] = element.trim()
            }
        }
    }
    return dataSet
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};

export const itemLabels = (items) => {
    return items.map((item) =>
        item.name.length > 25
            ? item.name.toLowerCase().slice(0, 25) + '...'
            : item.name.toLowerCase()
    );
};

// const buildFormData = (formData, data, parentKey) => {
//     if (
//         data &&
//         typeof data === 'object' &&
//         !(data instanceof Date) &&
//         !(data instanceof File) &&
//         !(data instanceof Blob)
//     ) {
//         Object.keys(data).forEach((key) => {
//             buildFormData(
//                 formData,
//                 data[key],
//                 parentKey ? `${parentKey}[${key}]` : key
//             );
//         });
//     } else {
//         const value = data == null ? '' : data;

//         formData.append(parentKey, value);
//     }
// };
