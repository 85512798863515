import React from 'react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Switch, useDisclosure } from '@chakra-ui/react';
import ConfirmDialog from './ConfirmDialog';

const CategoryCard = (props) => {
    const {
        item = {},
        selected,
        deleteClickHandler,
        enableToggleHandler,
        fetchCategories,
        selectedID,
        cardClick,
        editClick,
    } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div
            className={
                'category-card ' +
                (selected ? 'selected ' : '') +
                (item.enabled ? ' ' : ' disabled')
            }
            style={{ backgroundImage: `url(${item.image})` }}
            onClick={cardClick}
        >
            <div className='category-name-wrapper'>
                <div className='category-name'>{item.name}</div>
                <div className='price-action-container'>
                    <Switch
                        id='enable-disable'
                        size='md'
                        isChecked={item.enabled}
                        onChange={() =>
                            enableToggleHandler('category', item.id, () =>
                                fetchCategories(selectedID.menuID)
                            )
                        }
                    />

                    <button
                        className='btn btn-icon btn-primary-alt'
                        onClick={editClick}
                    >
                        <EditIcon />
                    </button>
                    <button
                        className='btn btn-icon btn-danger-alt'
                        onClick={onOpen}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
            <ConfirmDialog
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                deleteMenu={() =>
                    deleteClickHandler('categories', item.id, () =>
                        fetchCategories(selectedID.menuID)
                    )
                }
            />
        </div>
    );
};

export default CategoryCard;
