import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
} from '@chakra-ui/react';
import { getAllTerm, updateConfig, updateTerms } from '../../services/apiService';

const HomeConfigure = (props) => {
    const { ...rest } = props;
    console.log(rest);
    const [pageData, setPageData] = useState({
        hero_banner: '',
        privacy_policy: '',
        terms_and_conditions: '',
    });
    const handleChange = (e) => {
        const name = e.target.name;
        let value = null;
        if (e.target.type === 'file') {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }
        setPageData({ ...pageData, [name]: value });
    };
    const saveChanges = async () => {
        const localPayload ={
            type:"application",
            privacy_policy:"<p>asda</p>",
            terms_and_conditions:"<p>asaasda</p>"
        }
        try {
            const response = await updateTerms(2, localPayload);
            console.log(response);
        } catch (error) {}
    };

    return (
        <div className='page-configuration'>
            <p className='configpage-title'>
                You can change the home page of kisok application using the
                below options.
            </p>
            <div className='config-options'>
                <SimpleGrid columns={4} spacing={5} mb={5}>
                    <Box>
                        <FormControl
                            display='flex'
                            alignItems='center'
                            flexDirection={'column'}
                        >
                            <FormControl>
                                <FormLabel>Hero banner image </FormLabel>
                                <Input
                                    placeholder='Enter Image'
                                    // value={formData.ima}
                                    onChange={(e) => handleChange(e)}
                                    name='image'
                                    type='file'
                                />
                            </FormControl>

                            {/* {localImageURL ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={localImageURL}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : selectedItem.image &&
                                  formData.unset_image !== 1 ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={selectedItem.image}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={Logo}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                    </div>
                                )} */}
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={5}>
                    <Box>
                        <div className='form-group'>
                            <label className='control-label'>
                                Enter terms and conditions content
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: {
                                        items: [
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            '|',
                                            'link',
                                            '|',
                                            'undo',
                                            'redo',
                                        ],
                                    },
                                }}
                                data={pageData.privacy_policy}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setPageData({
                                        ...pageData,
                                        privacy_policy: data,
                                    });
                                }}
                            />
                        </div>
                    </Box>
                    <Box>
                        <div className='form-group'>
                            <label className='control-label'>
                                Enter privacy policy content
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: {
                                        items: [
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            '|',
                                            'link',
                                            '|',
                                            'undo',
                                            'redo',
                                        ],
                                    },
                                }}
                                data={pageData.privacy_policy}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setPageData({
                                        ...pageData,
                                        privacy_policy: data,
                                    });
                                }}
                            />
                        </div>
                    </Box>
                </SimpleGrid>
                <button onClick={saveChanges}>apply</button>
            </div>
        </div>
    );
};

export default HomeConfigure;
