import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
} from '@chakra-ui/react';

const CheckoutConfigure = () => {
    const [pageData, setPageData] = useState({
        terms_and_conditions: '',
    });
    const handleChange = (e) => {
        const name = e.target.name;
        let value = null;
        if (e.target.type === 'file') {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }
        setPageData({ ...pageData, [name]: value });
    };
    return (
        <div className='page-configuration'>
            <p className='configpage-title'>
                You can change the home page of kisok application using the
                below options.
            </p>
            <div className='config-options'>
                <SimpleGrid columns={4} spacing={5} mb={5}>
                    <Box>
                        <FormControl
                            display='flex'
                            alignItems='center'
                            flexDirection={'column'}
                        >
                            <FormControl>
                                <FormLabel>Carousel images </FormLabel>
                                <Input
                                    placeholder='Enter Image'
                                    // value={formData.ima}
                                    onChange={(e) => handleChange(e)}
                                    name='image'
                                    type='file'
                                />
                            </FormControl>

                            {/* {localImageURL ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={localImageURL}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : selectedItem.image &&
                                  formData.unset_image !== 1 ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={selectedItem.image}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={Logo}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                    </div>
                                )} */}
                        </FormControl>
                    </Box>
                </SimpleGrid>
            </div>
        </div>
    );
};


export default CheckoutConfigure