import { Box } from '@chakra-ui/react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState } from 'react';
import { deleteTerms, updateTerms } from '../../services/apiService';
import { updateLabel } from '../../constants/typecode';

const RenderEditor = (props) => {
    const { value, id, type, fetchAllTerms } = props;
    const [editorData, setEdtitorData] = useState(value || '');

    const updateTermData = async (id) => {
        const obj = {
            type,
            content: editorData,
        };
        try {
            const response = await updateTerms(id, obj);
            if (response.status === 200) {
                fetchAllTerms();
            }
        } catch (error) {}
    };
    const deleteTermData = async () => {
        try {
            const response = await deleteTerms(id);
            if (response.status === 200) {
                fetchAllTerms();
            }
        } catch (error) {}
    };
    return (
        <div className='editor-container'>
            <div className='form-group'>
                <label className='control-label'>
                    {updateLabel[type] || ''}
                </label>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'link',
                                '|',
                                'undo',
                                'redo',
                            ],
                        },
                    }}
                    data={editorData}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setEdtitorData(data);
                    }}
                />
            </div>
            <div className='actions-container'>
                <button
                    className='btn btn-primary'
                    onClick={() => updateTermData(id)}
                >
                    update
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => deleteTermData(id)}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default RenderEditor;
