import { Route, Routes } from 'react-router-dom';
import RequireAuth from './auth/RequireAuth';
import ConfigureKiosk from './pages/ConfigureKiosk';
import Layout from './pages/Layout';
import Login from './pages/Login';
import MenuEdit from './pages/MenuEdit';
import AnalyticsDashboard from './pages/Analytics';
import { useContext } from 'react';
import { CinemaContext } from './contexts/CinemaContext';
import { ROLES } from './constants/typecode';

function App() {
    const NotFound = () => <p>Page not found</p>;
    const { userRole } = useContext(CinemaContext);

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                }
            >
                {userRole !== ROLES.PROPERTY_ADMIN && (
                    <Route index element={<MenuEdit />} />
                )}
                {userRole !== ROLES.OUTLET_MANAGER && (
                    <Route index element={<AnalyticsDashboard />} />
                )}
                <Route exact path='/menu' element={<MenuEdit />} />
                <Route exact path='/analytics' element={<AnalyticsDashboard />} />
                <Route exact path='/configure' element={<ConfigureKiosk />} />
            </Route>
            <Route exact path='/login' element={<Login />} />
            <Route path='*' exact={true} element={<NotFound />} />
        </Routes>
    );
}

export default App;
