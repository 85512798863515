import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Switch,
    useToast,
} from '@chakra-ui/react';
import { createCategory } from '../../services/apiService';
import { serialize } from 'object-to-formdata';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { trimSpaceFromString } from '../../helpers/commonHelper';

const CategoryModal = (props) => {
    const { isOpen, onClose, selectedID, selectedCategory, fetchCategories } =
        props;
    const enabledFlag  = selectedCategory.enabled ?? 1;
    const [formData, setFormData] = useState({
        menu_id: selectedID.menuID || '',
        name: selectedCategory.name || '',
        display_name: selectedCategory.display_name || '',
        description: selectedCategory.description || '',
        image: selectedCategory.image || undefined,
        priority: selectedCategory.priority || 0,
        enabled: Number(enabledFlag),

    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const toast = useToast();

    const saveClickHandler = async () => {
        if (selectedCategory.id) {
            if (formData.image && typeof formData.image !== 'object') {
                delete formData?.image;
            }
        }
        try {
            setLoading(true);
            trimSpaceFromString(formData);
            const datatoSend = serialize(formData);
            const response = await createCategory(
                datatoSend,
                selectedCategory.id
            );
            if (response.status === 200 || response.status === 201) {
                onClose();
                fetchCategories(selectedID.menuID);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 422 || response.status === 503) {
                const message =
                    response.data.message ||
                    'Something went Wrong!! Please try again.';
                setError(message);
            }
            if (response.status === 413) {
                const message = 'Image size is too large.';
                setError(message);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setError('');
        let value = null;
        const name = e.target.name;
        const type = e.target.type;
        if (type === 'checkbox') {
            value = e.target.checked;
            if (name === 'enabled') {
                value = Number(value);
            }
        } else if (type === 'file') {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }
        setFormData({ ...formData, [name]: value });
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {selectedCategory.id ? 'Edit' : 'Add'} Category
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={3}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            placeholder='Enter Category Name'
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                            name='name'
                            type='text'
                        />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Display Name</FormLabel>
                        <Input
                            placeholder='Enter Category Display Name'
                            value={formData.display_name}
                            onChange={(e) => handleChange(e)}
                            name='display_name'
                            type='text'
                        />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            placeholder='Enter Category Description'
                            value={formData.description}
                            onChange={(e) => handleChange(e)}
                            name='description'
                            type='text'
                        />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Priority</FormLabel>
                        <Input
                            placeholder='Enter Priority'
                            value={formData.priority}
                            onChange={(e) => handleChange(e)}
                            name='priority'
                            type='number'
                        />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Image</FormLabel>
                        <Input
                            placeholder='Enter Image'
                            // value={formData.ima}
                            onChange={(e) => handleChange(e)}
                            name='image'
                            type='file'
                        />
                    </FormControl>
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='email-alerts' mb='0'>
                            Category Enable/Disable
                        </FormLabel>
                        <Switch
                            id='enable'
                            type={'checkbox'}
                            name='enabled'
                            isChecked={Boolean(formData.enabled)}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormControl>
                    {error && (
                        <p className='error-message'>
                            <InfoOutlineIcon /> {error}
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        colorScheme='gray'
                        variant='outline'
                        onClick={saveClickHandler}
                        ml={2}
                        disabled={loading}
                        isLoading={loading}
                        loadingText='Submitting'
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CategoryModal;
