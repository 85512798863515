import React from 'react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Switch, useDisclosure } from '@chakra-ui/react';
import ConfirmDialog from './ConfirmDialog';
import Loading from './Loading';

const AddonCard = (props) => {
    const {
        selected,
        addon,
        handleAddonEditClickHandler,
        deleteClickHandler,
        enableToggleHandler,
        fetchAddons,
        selectedID,
        cardClick,
        editClick,
    } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div
            className={
                'addon-card ' +
                (selected ? 'selected ' : '') +
                (addon.enabled ? ' ' : ' disabled')
            }
            onClick={cardClick}
        >
            <div className='addon-name-wrapper'>
                <div className='addon-name'>{addon.name}</div>
                <div className='price-action-container'>
                    <Switch
                        id='enable-disable'
                        size='md'
                        isChecked={addon.enabled}
                        onChange={() =>
                            enableToggleHandler('addon', addon.id, () =>
                                fetchAddons(
                                    selectedID.itemID,
                                    selectedID.subAddonsID
                                )
                            )
                        }
                    />
                    <button
                        className='btn btn-icon btn-primary-alt'
                        onClick={editClick}
                    >
                        <EditIcon />
                    </button>
                    <button
                        className='btn btn-icon btn-danger-alt'
                        onClick={onOpen}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
            <ConfirmDialog
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                deleteMenu={() =>
                    deleteClickHandler('addons', addon.id, () =>
                        fetchAddons(selectedID.itemID, selectedID.subAddonsID)
                    )
                }
            />
        </div>
    );
};
const SubAddonCard = (props) => {
    const {
        item = {},
        selected,
        addonList,
        isAddonLoading,
        handleAddonEditClickHandler,
        deleteClickHandler,
        enableToggleHandler,
        fetchSubAddons,
        selectedID,
        fetchAddons,
        cardClick,
        editClick,
        handleEditClickHandler,
        setSelectedAddon,
        addonOpen,
    } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <div
                className={
                    'subaddon-card ' +
                    (selected ? 'selected ' : '') +
                    (item.enabled ? ' ' : ' disabled')
                }
                onClick={cardClick}
            >
                <div className='subaddon-name-wrapper'>
                    <div className='subaddon-name'>
                        <div>{item.name}</div>
                        <div className='has-customization'>
                            {item.select_type == 'single'
                                ? 'Single Select'
                                : 'Multi Select'}
                        </div>
                    </div>
                    <div className='price-action-container'>
                        <Switch
                            id='enable-disable'
                            size='md'
                            isChecked={item.enabled}
                            onChange={() =>
                                enableToggleHandler('sub-addon', item.id, () =>
                                    fetchSubAddons(selectedID.itemID)
                                )
                            }
                        />

                        <button
                            className='btn btn-icon btn-primary-alt'
                            onClick={editClick}
                        >
                            <EditIcon />
                        </button>
                        <button
                            className='btn btn-icon btn-danger-alt'
                            onClick={onOpen}
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                </div>

                <ConfirmDialog
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    deleteMenu={() =>
                        deleteClickHandler('sub-addons', item.id, () =>
                            fetchSubAddons(selectedID.itemID)
                        )
                    }
                />
            </div>
            {selected && (
                <div className='addons-wrapper'>
                    {isAddonLoading ? (
                        <Loading />
                    ) : (
                        addonList.length > 0 &&
                        addonList.map((addon) => (
                            <AddonCard
                                key={addon.id}
                                addon={addon}
                                handleAddonEditClickHandler={
                                    handleAddonEditClickHandler
                                }
                                selected={selectedID.addonsID === addon.id}
                                deleteClickHandler={deleteClickHandler}
                                enableToggleHandler={enableToggleHandler}
                                fetchAddons={fetchAddons}
                                selectedID={selectedID}
                                cardClick={() =>
                                    handleEditClickHandler(
                                        'addonsID',
                                        addon,
                                        true,
                                        false,
                                        addonOpen,
                                        setSelectedAddon
                                    )
                                }
                                editClick={() =>
                                    handleEditClickHandler(
                                        'addonsID',
                                        addon,
                                        true,
                                        true,
                                        addonOpen,
                                        setSelectedAddon
                                    )
                                }
                                addOption
                            />
                        ))
                    )}
                    <button
                        className='btn btn-ternary-alt'
                        onClick={() =>
                            handleEditClickHandler(
                                'addonsID',
                                {},
                                true,
                                true,
                                addonOpen,
                                setSelectedAddon
                            )
                        }
                    >
                        Add Option
                    </button>
                </div>
            )}
        </>
    );
};

export default SubAddonCard;
