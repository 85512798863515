// contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios'; // Axios for API calls
import { getToken } from '../services/apiService';

// Create the context
export const CinemaContext = createContext({});

// CinemaContext provider component
export const CinemaProvider = ({ children }) => {
    const [cinemaDetails, setCinemaDetails] = useState({});
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch cinema details from the API
    const getCinemaDetails = async () => {
        try {
            setLoading(true);
            setCinemaDetails({});
            setUserRole(null);
            const token = getToken();
            if (!token) {
                return;
            }
            const response = await axios.get('/api/v1/token');
            if(response.status===200){
               const data= response.data.data;
               setCinemaDetails(data);
               setUserRole(data.user_role[0]);   
            }
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            setCinemaDetails(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CinemaContext.Provider value={{ cinemaDetails, userRole, loading, getCinemaDetails, setUserRole }}>
            {children}
        </CinemaContext.Provider>
    );
};
