import React from 'react';
import { Table } from 'antd';
import { formatNumber } from '../helpers/commonHelper';

const ItemWiseRevenue = ({ analyticsData }) => {
    if (!analyticsData || !analyticsData.overview) return null;

    const topSellingItems = analyticsData.topSellingItems || [];
    const leastSellingItems = analyticsData.leastSellingItems || [];

    // Combine both arrays
    const allItems = [...topSellingItems, ...leastSellingItems];

    // Use a Map to remove duplicates and keep the last occurrence
    const uniqueItemsMap = new Map();

    allItems.forEach((item) => {
        uniqueItemsMap.set(item.name, item);
    });

    // Convert the Map back to an array
    const aggregatedItems = Array.from(uniqueItemsMap.values());

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '31%',
        },
        {
            title: 'Sold Quantity',
            dataIndex: 'sold_quantity',
            key: 'sold_quantity',
            align: 'center',
            width: '38%',
            sorter: (a, b) => a.sold_quantity - b.sold_quantity,
        },
        {
            title: 'Total Revenue',
            dataIndex: 'total_earning',
            key: 'total_earning',
            render: (value) => `₹${formatNumber(value)}`,
            align: 'center',
            sorter: (a, b) => a.total_earning - b.total_earning,
        },
    ];

    return (
            <Table
                dataSource={aggregatedItems}
                columns={columns}
                rowKey='name' // Ensure each row has a unique key
                pagination={{
                    pageSize: 10, // Set the maximum number of rows per page
                }}
                className='revenue-table'
            />
    );
};

export default ItemWiseRevenue;
