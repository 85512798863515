import { React, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import { CinemaContext } from "../contexts/CinemaContext";

const Layout = () => {

    const { getCinemaDetails } = useContext(CinemaContext);

    useEffect(() => {
        getCinemaDetails();
    }, []);
  return (
    <div className="main-app">
      <Header />
      <Outlet />
    </div>
  );
};

export default Layout;
