import {
    Box,
    FormControl,
    FormLabel,
    Select,
    SimpleGrid,
} from '@chakra-ui/react';
import { ClassicEditor } from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import CheckoutConfigure from '../components/kiosk-configure-components/CheckoutConfigure';
import HomeConfigure from '../components/kiosk-configure-components/HomeConfigure';
import ImageUpload from '../components/kiosk-configure-components/ImageUpload';
import ListingConfigure from '../components/kiosk-configure-components/ListingConfigure';
import NewConfigModal from '../components/kiosk-configure-components/NewConfigModal';
import RenderEditor from '../components/kiosk-configure-components/RenderEditor';
import ThankyouConfigure from '../components/kiosk-configure-components/ThankyouConfigure';
import { getAllTerm, updateTerms } from '../services/apiService';

const editorType = ['terms_and_conditions', 'privacy_policy'];
const imageType = ['hero_banner', 'listing_slider_img', 'checkout_slider_img'];

const ConfigureKiosk = () => {
    const [selectedPage, setSelectedPage] = useState('HP');
    const [nodelType, setModelType] = useState('property');
    const [loading, setLoading] = useState(false);
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [termList, setTermList] = useState([]);

    useEffect(() => {
        fetchAllTerms();
    }, []);
    const fetchAllTerms = async () => {
        setLoading(true);
        try {
            const response = await getAllTerm();
            if (response.status === 200) {
                const data = response.data.data || {};
                if (data.terms) {
                    setTermList(data.terms);
                    console.log(data.terms);
                }
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const componentProps = {
        nodelType: nodelType,
        selectedPage: selectedPage,
    };
    const componentManifest = Object.freeze({
        HP: <HomeConfigure {...componentProps} />,
        LP: <ListingConfigure {...componentProps} />,
        CP: <CheckoutConfigure {...componentProps} />,
        TP: <ThankyouConfigure {...componentProps} />,
    });
    const selectedComponent = componentManifest[selectedPage];
    return (
        <>
            <div className='configure-page'>
                <div className='left-sidebar'>
                    <div className='page-selection'>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>
                                    Select page for customization
                                </FormLabel>
                                <Select
                                    name='selectedPage'
                                    value={selectedPage}
                                    onChange={(e) =>
                                        setSelectedPage(e.target.value)
                                    }
                                >
                                    <option value='HP'>Home Page</option>
                                    <option value='LP'>Listing Page</option>
                                    <option value='CP'>Checkout Page</option>
                                    <option value='TP'>Thankyou Page</option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>
                                    Select page for customization
                                </FormLabel>
                                <Select
                                    name='model_type'
                                    value={nodelType}
                                    onChange={(e) =>
                                        setModelType(e.target.value)
                                    }
                                >
                                    <option value='kiosk'>Kiosk</option>
                                    <option value='outelet'>Outelet</option>
                                    <option value='property'>Property</option>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <div className='right-sidebar'>
                    <div className='customization-container'>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            termList.map((term, i) => {
                                if (editorType.includes(term.type)) {
                                    return (
                                        <RenderEditor
                                            key={term.id}
                                            value={term.content}
                                            id={term.id}
                                            type={term.type}
                                            fetchAllTerms={fetchAllTerms}
                                            {...term}
                                        />
                                    );
                                } else if (imageType.includes(term.type)) {
                                    return (
                                        <ImageUpload
                                            key={term.id}
                                            value={term.content}
                                            id={term.id}
                                            type={term.type}
                                            fetchAllTerms={fetchAllTerms}
                                            {...term}
                                        />
                                    );
                                }
                            })
                        )}
                        <button className='btn btn-primary' onClick={()=> setIsAddNewOpen(true)}>
                            Add new config
                        </button>
                    </div>
                </div>
            </div>
            {isAddNewOpen && (
                <NewConfigModal
                    isOpen={isAddNewOpen}
                    onOpen={()=> setIsAddNewOpen(true)}
                    onClose={()=> setIsAddNewOpen(false)}
                />
            )}
        </>
    );
};

export default ConfigureKiosk;
