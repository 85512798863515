import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Select,
    Input,
    SimpleGrid,
    Box,
    Switch,
    useToast,
} from '@chakra-ui/react';
import { DeleteIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { serialize } from 'object-to-formdata';
import { createAddon, getTaxes } from '../../services/apiService';
import { Multiselect } from 'multiselect-react-dropdown';
import Logo from '../../assets/vserveCinemaLogo.png';
import { convertImageToBase64, trimSpaceFromString } from '../../helpers/commonHelper';

const AddonModal = (props) => {
    const { isOpen, onClose, selectedAddon, selectedID, fetchAddons } = props;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [taxList, setTaxList] = useState([]);
    const [selectedTaxList, setSelectedTaxList] = useState([]);
    const [localImageURL, setLocalImageURL] = useState('');
    const enabledFlag  = selectedAddon.enabled ?? 1;

    const [formData, setFormData] = useState({
        menu_item_id: selectedID.itemID || '',
        menu_subaddon_id: selectedID.subAddonsID || '',
        name: selectedAddon.name || '',
        price: selectedAddon.price ?? 0,
        prep_time: selectedAddon.prep_time || 10,
        type: selectedAddon.type || '',
        priority: selectedAddon.priority || 0,
        enabled: Number(enabledFlag),
        taxes: [],
        sku_code: selectedAddon.sku_code || '',
        measurement_unit: selectedAddon.measurement_unit || '',
    });
    const toast = useToast();
    useEffect(() => {
        fetchTaxesList();
    }, []);
    useEffect(() => {
        updateTaxIntoRender();
    }, [selectedAddon?.taxes]);

    const handleChange = (e) => {
        setError('');
        let value = null;
        const name = e.target.name;
        const type = e.target.type;
        if (type === 'checkbox') {
            value = e.target.checked;
            if (name === 'enabled' || name === 'is_recommended') {
                value = Number(value);
            }
        }  else if (type === 'file') {
            value = e.target.files[0];
            convertImageToBase64(value, (imgURL) => setLocalImageURL(imgURL));
        } else {
            value = e.target.value;
        }
        setFormData({ ...formData, [name]: value });
    };
    const fetchTaxesList = async () => {
        try {
            const response = await getTaxes();
            if (response.status === 200) {
                const data = response.data.data || {};
                if (data && data.taxes && data.taxes.length) {
                    const mapdata = data.taxes?.map((item) => ({
                        label: item.name,
                        value: item.id,
                        ...item,
                    }));
                    setTaxList(mapdata);
                }
            }
        } catch (error) {}
    };
    const updateTaxIntoRender = () => {
        if (selectedAddon?.taxes) {
            const mapdata = selectedAddon.taxes?.map((item) => ({
                label: item.name,
                value: item.id,
                ...item,
            }));
            setSelectedTaxList(mapdata);
            setFormData({
                ...formData,
                taxes: selectedAddon.taxes?.map((x) => x.id),
            });
        }
    };
    const onSelect = (selectedList, selectedItem) => {
        setFormData({
            ...formData,
            taxes: selectedList?.map((x) => x.value),
        });
        setSelectedTaxList(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setFormData({
            ...formData,
            taxes: selectedList?.map((x) => x.value),
        });
        setSelectedTaxList(selectedList);
    };
    const saveClickHandler = async () => {
        try {
            setLoading(true);
            trimSpaceFromString(formData);
            const datatoSend = serialize(formData);
            const response = await createAddon(datatoSend, selectedAddon.id);
            if (response.status === 201 || response.status === 200) {
                onClose();
                fetchAddons(selectedID.itemID, selectedID.subAddonsID);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 422 || response.status === 503) {
                const message =
                    response.data.message ||
                    'Something went Wrong!! Please try again.';
                setError(message);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } finally {
            setLoading(false);
        }
    };
    const deleteImage = () => {
        setLocalImageURL('');
        setFormData({ ...formData, unset_image: 1, image: undefined });
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {selectedAddon.id ? 'Edit' : 'Add'} Option
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={3}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            placeholder='Enter Option Name'
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                            name='name'
                            type='text'
                        />
                    </FormControl>

                    <SimpleGrid columns={3} spacing={5}>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Priority</FormLabel>
                                <Input
                                    placeholder='Enter Priority'
                                    value={formData.priority}
                                    onChange={(e) => handleChange(e)}
                                    name='priority'
                                    type='number'
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Price</FormLabel>
                                <Input
                                    placeholder='Enter Price'
                                    value={formData.price}
                                    onChange={(e) => handleChange(e)}
                                    name='price'
                                    type='number'
                                />
                            </FormControl>
                        </Box>
                        <Box>
                        <FormControl mb={3}>
                                <FormLabel>Preparation Time</FormLabel>
                                <Select
                                    placeholder='Select Option'
                                    value={formData.prep_time}
                                    onChange={(e) => handleChange(e)}
                                    name='prep_time'
                                >
                                    <option value='10'>10 minutes</option>
                                    <option value='20'>20 minutes</option>
                                </Select>
                            </FormControl>
                        </Box>

                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={5}>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Item Type</FormLabel>
                                <Select
                                    placeholder='Select Option'
                                    value={formData.type}
                                    onChange={(e) => handleChange(e)}
                                    name='type'
                                >
                                    <option value={'veg'}>Vegeterian</option>
                                    <option value={'non_veg'}>
                                        Non Vegeterian
                                    </option>
                                    <option value={'non_alcohol_beverages'}>
                                        Non Alcohol
                                    </option>
                                </Select>
                            </FormControl>
                            <FormControl mb={3}>
                                <FormLabel>Measurement Unit</FormLabel>
                                <Input
                                    placeholder='Enter Measurement Unit'
                                    value={formData.measurement_unit}
                                    onChange={(e) => handleChange(e)}
                                    name='measurement_unit'
                                    type='text'
                                />
                            </FormControl>
                            <FormControl mb={3}>
                                <FormLabel>HO Code</FormLabel>
                                <Input
                                    placeholder='Enter HO Code'
                                    value={formData.sku_code}
                                    onChange={(e) => handleChange(e)}
                                    name='sku_code'
                                    type='text'
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl
                                display='flex'
                                alignItems='center'
                                mb='3'
                                flexDirection={'column'}
                            >
                                <FormControl mb={3}>
                                    <FormLabel>Image ( 554* 554 )</FormLabel>
                                    <Input
                                        placeholder='Enter Image'
                                        // value={formData.ima}
                                        onChange={(e) => handleChange(e)}
                                        name='image'
                                        type='file'
                                    />
                                </FormControl>
                                {/* <Switch
                                    id='unset_image'
                                    type={'checkbox'}
                                    name='unset_image'
                                    isChecked={Boolean(formData.unset_image)}
                                    onChange={(e) => handleChange(e)}
                                /> */}
                                {localImageURL ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={localImageURL}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : selectedAddon.image &&
                                  formData.unset_image !== 1 ? (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={selectedAddon.image}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                        <button
                                            className='btn btn-icon btn-danger'
                                            onClick={deleteImage}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ) : (
                                    <div className='item-image-wrapper'>
                                        <img
                                            src={Logo}
                                            alt='item'
                                            className={
                                                'item-image ' +
                                                (formData.unset_image === 1
                                                    ? 'remove'
                                                    : '')
                                            }
                                        />
                                    </div>
                                )}
                            </FormControl>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={1} spacing={5}>
                        <Box>
                            <FormControl mb={3}>
                                <FormLabel>Tax</FormLabel>
                                <Multiselect
                                    options={taxList}
                                    displayValue='name'
                                    selectedValues={selectedTaxList}
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={5}>
                        <Box>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='0'>
                                    Item Enable/Disable
                                </FormLabel>
                                <Switch
                                    id='enable'
                                    type={'checkbox'}
                                    name='enabled'
                                    isChecked={Boolean(formData.enabled)}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>
                    {error && (
                        <p className='error-message'>
                            <InfoOutlineIcon /> {error}
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        colorScheme='gray'
                        variant='outline'
                        onClick={saveClickHandler}
                        ml={2}
                        disabled={loading}
                        isLoading={loading}
                        loadingText='Submitting'
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddonModal;
