import { serialize } from 'object-to-formdata';
import React, { useState } from 'react';
import { updateLabel } from '../../constants/typecode';
import { deleteTerms, updateTerms } from '../../services/apiService';

const ImageUpload = (props) => {
    const { value, id, type, content, fetchAllTerms } = props;

    const [image, setImage] = useState(null);

    const handleChange = (e) => {
        setImage(e.target.files[0]);
    };
    const updateTermData = async (id) => {
        const obj = {
            type,
            content: image,
        };
        const datatoSend = serialize(obj);

        try {
            const response = await updateTerms(id, datatoSend);
            if (response.status === 200) {
                fetchAllTerms();
            }
        } catch (error) {}
    };
    const deleteTermData = async () => {
        try {
            const response = await deleteTerms(id);
            if (response.status === 200) {
                fetchAllTerms();
            }
        } catch (error) {}
    };
    return (
        <div className='editor-container'>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div className='form-group'>
                    <label className='control-label'>
                        {updateLabel[type] || ''}
                    </label>
                    <input
                        type='file'
                        id='files'
                        multiple={type !== 'hero_banner'}
                        placeholder='Enter Image'
                        onChange={(e) => handleChange(e)}
                        name='image'
                    />
                </div>
                <img
                    src={content}
                    alt={'asd'}
                    className='kiosk-current-image'
                />
            </div>
            <div className='actions-container'>
                <button
                    className='btn btn-primary'
                    onClick={() => updateTermData(id)}
                >
                    update
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => deleteTermData(id)}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default ImageUpload;
