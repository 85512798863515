import React from 'react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Switch, useDisclosure } from '@chakra-ui/react';
import ConfirmDialog from './ConfirmDialog';

const MenuCard = (props) => {
    const {
        item = {},
        selected,
        cardClick,
        editClick,
        deleteClickHandler,
        enableToggleHandler,
        fetchMenus,
    } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <div
            className={
                'menu-card ' +
                (selected ? 'selected ' : '') +
                (item.enabled ? ' ' : ' disabled')
            }
            onClick={cardClick}
        >
            <div className='menu-name-wrapper'>
                <div className='menu-name'>{item.name}</div>
                <div className='price-action-container'>
                    <Switch
                        id='enable-disable'
                        size='md'
                        isChecked={item.enabled}
                        onChange={() =>
                            enableToggleHandler('menu', item.id, fetchMenus)
                        }
                    />
                    <button
                        className='btn btn-icon btn-primary-alt'
                        onClick={editClick}
                    >
                        <EditIcon />
                    </button>
                    <button
                        className='btn btn-icon btn-danger-alt'
                        onClick={onOpen}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
            <ConfirmDialog
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                deleteMenu={() =>
                    deleteClickHandler('menus', item.id, fetchMenus)
                }
            />
        </div>
    );
};

export default MenuCard;
