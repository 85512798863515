import React from 'react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Switch, useDisclosure } from '@chakra-ui/react';
import ConfirmDialog from './ConfirmDialog';

const ItemCard = (props) => {
    const {
        item = {},
        image,
        price,
        deleteClickHandler,
        selected,
        itemCardClickHandler,
        itemEditClickHandler,
        enableToggleHandler,
        fetchItems,
        selectedID,
        cardClick,
        editClick,
    } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div
            className={
                'item-card ' +
                (selected ? 'selected' : '') +
                (item.enabled ? ' ' : ' disabled')
            }
            onClick={cardClick}
        >
            {image && (
                <div className='img-wrapper'>
                    <img
                        src={image}
                        alt='pvr-logo'
                        className='item-image'
                        loading='lazy'
                    />
                </div>
            )}
            {(item.type === 'veg' || item.type === 'non_veg') && (
                <div
                    className={
                        'item-type-icon ' +
                        (item.type === 'non_veg' ? 'non-veg' : 'veg')
                    }
                ></div>
            )}
            <div className='item-details-container'>
                <div className='item-name'>{item.name || ''}</div>
                {item.description && (
                    <div
                        className='item-description'
                        dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                )}
                <div className='item-code'>
                    <span>
                        HO Code : <b>{item.sku_code ?? '--'}</b> | Measurement
                        Unit : <b>{item.measurement_unit ?? '--'}</b>
                    </span>
                </div>
                <div className='price'>
                    ₹{' '}
                    {price.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </div>
            </div>

            <div className='price-action-container'>
                <Switch
                    id='enable-disable'
                    size='md'
                    isChecked={item.enabled}
                    onChange={() =>
                        enableToggleHandler('item', item.id, () =>
                            fetchItems(selectedID.categoryID)
                        )
                    }
                />

                <button
                    className='btn btn-icon btn-primary'
                    onClick={editClick}
                >
                    <EditIcon />
                </button>
                <button className='btn btn-icon btn-danger' onClick={onOpen}>
                    <DeleteIcon />
                </button>
            </div>
            <ConfirmDialog
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                deleteMenu={() =>
                    deleteClickHandler('items', item.id, () =>
                        fetchItems(selectedID.categoryID)
                    )
                }
            />
        </div>
    );
};

export default ItemCard;
