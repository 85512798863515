import React, { useState, useEffect, useContext } from 'react';
import { Select, DatePicker, Button, Card, Row, Col, Spin } from 'antd';
import dayjs from 'dayjs';
import {
    getProperties,
    getOutlets,
    getKiosks,
    getAnalytics,
} from '../services/apiService';
import LeastSellingItem from '../components/LeastSellingItem';
import TopSellingItem from '../components/TopSellingItem';
import RevenueComparison from '../components/RevenueComparison';
import ItemWiseRevenue from '../components/ItemWiseRevenue';
import { ROLES } from '../constants/typecode';
import { CinemaContext } from '../contexts/CinemaContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AnalyticsDashboard = () => {
    const [properties, setProperties] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [kiosks, setKiosks] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState(['ALL']);
    const [selectedOutlets, setSelectedOutlets] = useState(['ALL']);
    const [selectedKiosks, setSelectedKiosks] = useState(['ALL']);
    const [dateRange, setDateRange] = useState([
        dayjs().subtract(1, 'month'),
        dayjs(),
    ]);
    const [maxCount, setMaxCount] = useState(5);
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { userRole } = useContext(CinemaContext);
    const entity =
        ROLES.ADMIN === userRole || ROLES.PROPERTY_ADMIN === userRole ? 'outlet' : 'kiosk';
    const [revenueCompare, setRevenueCompare] = useState(entity);

    useEffect(() => {
        // Fetch all properties, outlets, and kiosks at once when the component loads
        fetchAllData();
    }, []);

    useEffect(() => {
        fetchAnalytics();
    }, [selectedOutlets, selectedKiosks, dateRange, maxCount, revenueCompare]);

    const fetchAllData = async () => {
        try {
            if (ROLES.ADMIN === userRole) {
                await fetchProperties();
            }
            if (ROLES.ADMIN === userRole || ROLES.PROPERTY_ADMIN === userRole) {
                await fetchOutlets();
            }
            await fetchKiosks();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchProperties = async () => {
        try {
            const response = await getProperties();
            const data = response.data?.data?.properties;
            if (response.status === 200) {
                setProperties(data);
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const fetchOutlets = async (propertyIds = []) => {
        try {
            const params =
                propertyIds.length > 0
                    ? { filter_property_ids: propertyIds }
                    : {};
            const response = await getOutlets(params);
            const data = response.data?.data?.outlets;
            if (response.status === 200) {
                setOutlets(data);
            }
        } catch (error) {
            console.error('Error fetching outlets:', error);
        }
    };

    const fetchKiosks = async (outletIds = []) => {
        try {
            const params =
                outletIds.length > 0 ? { filter_outlet_ids: outletIds } : {};
            const response = await getKiosks(params);
            const data = response.data?.data?.kiosks;
            if (response.status === 200) {
                setKiosks(data);
            }
        } catch (error) {
            console.error('Error fetching kiosks:', error);
        }
    };

    const fetchAnalytics = async () => {
        setLoading(true);
        try {
            const payload = {
                start: dateRange[0]?.format('YYYY-MM-DD') + ' 06:00:00',
                end: dateRange[1]?.format('YYYY-MM-DD') + ' 05:59:59',
                analyticsComponents: [
                    'overview',
                    'topSellingItems',
                    'leastSellingItems',
                    'revenueComparision',
                ],
                take: maxCount,
                group_by: revenueCompare,
            };

            if (!selectedProperties.includes('ALL')) {
                payload.filter_property_ids = selectedProperties;
            }

            if (
                !selectedOutlets.includes('ALL') &&
                selectedOutlets.length > 0
            ) {
                payload.filter_outlet_ids = selectedOutlets;
            }

            if (!selectedKiosks.includes('ALL') && selectedKiosks.length > 0) {
                payload.filter_kiosk_ids = selectedKiosks;
            }

            const response = await getAnalytics(payload);
            const data = response.data.data;
            setAnalyticsData(data);
        } catch (error) {
            console.error('Error fetching analytics:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePropertyChange = async (values) => {
        if (values.includes('ALL')) {
            // If "All" is selected, clear all other selections
            if (selectedProperties.includes('ALL')) {
                const propertyID = values.filter((val) => val !== 'ALL');
                setSelectedProperties(propertyID);
                const response = await getOutlets({ filter_property_ids: propertyID });
                const newOutlets = response.data?.data?.outlets || [];
                setOutlets(newOutlets);
                const outletIDs = newOutlets.map(outlet => outlet.id);
                fetchKiosks(outletIDs);
            } else {
                setSelectedProperties(['ALL']);
                fetchProperties();
            }
        } else {
            // If specific properties are selected
            setSelectedProperties(values);
            const response = await getOutlets({ filter_property_ids: values });
            const newOutlets = response.data.data.outlets;
            setOutlets(newOutlets);
            const outletIDs = newOutlets.map(outlet => outlet.id);
            fetchKiosks(outletIDs);
        }
        
        // Reset outlets and kiosks selection to 'ALL'
        setSelectedOutlets(['ALL']);
        setSelectedKiosks(['ALL']);
    };

    const handleOutletChange = (values) => {
        if (values.includes('ALL')) {
            // If "All" is selected, clear all other selections
            if (selectedOutlets.includes('ALL')) {
                const outletID = values.filter((val) => val !== 'ALL');
                setSelectedOutlets(outletID);
                fetchKiosks(outletID);
            } else {
                setSelectedOutlets(['ALL']);
                fetchKiosks(outlets); // Fetch all kiosks when "ALL" is selected for outlet
            }
        } else {
            // If any other value is selected, remove "All" and set the selected values
            setSelectedOutlets(values);
            fetchKiosks(values); // Fetch kiosks for the selected outlets
        }
        // Reset kiosks when outlets change
        setSelectedKiosks(['ALL']);
    };

    const handleKioskChange = (values) => {
        if (values.includes('ALL')) {
            if (selectedKiosks.includes('ALL')) {
                setSelectedKiosks(values.filter((val) => val !== 'ALL'));
            } else {
                setSelectedKiosks(['ALL']);
            }
        } else {
            // If any other value is selected, remove "All" and set the selected values
            setSelectedKiosks(values);
        }
    };

    const rangePresets = [
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 15 Days',
            value: [dayjs().add(-15, 'd'), dayjs()],
        },
        {
            label: 'Last Month',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 3 Months',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const resetAnalytics = () => {
        setSelectedProperties(['ALL']);
        setSelectedOutlets(['ALL']);
        setSelectedKiosks(['ALL']);
        setDateRange([dayjs().subtract(1, 'month'), dayjs()]);
        setMaxCount(5);
        setAnalyticsData(null);
        setRevenueCompare(entity);
        fetchAllData();
    };

    return (
        <div
            style={{
                padding: 24,
                display: 'flex',
            }}
        >
            <Row gutter={16}>
                <Col
                    span={4}
                    style={{
                        position: 'sticky', // Makes the column sticky
                        top: 20, // Distance from the top of the viewport when it becomes sticky
                        paddingLeft: 8,
                        paddingRight: 8,
                        height: '100%', // Ensure height is 100% of the container
                    }}
                    className='analytics-settings'
                >
                    <Card
                        title='Analytics Settings'
                        style={{ flex: 1, overflow: 'auto', height: '100%' }}
                    >
                        {ROLES.ADMIN === userRole ? (
                            <Select
                                mode='multiple'
                                style={{ width: '100%', marginBottom: 16 }}
                                placeholder='Select Cinema'
                                onChange={handlePropertyChange}
                                value={selectedProperties}
                            >
                                <Option value='ALL'>All Cinemas</Option>
                                {properties.map((property) => (
                                    <Option
                                        key={property.id}
                                        value={property.id}
                                    >
                                        {property.name}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            ''
                        )}
                        {ROLES.ADMIN === userRole || ROLES.PROPERTY_ADMIN === userRole ? (
                            <Select
                                mode='multiple'
                                style={{ width: '100%', marginBottom: 16 }}
                                placeholder='Select Outlet'
                                onChange={handleOutletChange}
                                value={selectedOutlets}
                            >
                                <Option value='ALL'>All Outlets</Option>
                                {outlets.map((outlet) => (
                                    <Option key={outlet.id} value={outlet.id}>
                                        {outlet.name}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            ''
                        )}
                        <Select
                            mode='multiple'
                            style={{ width: '100%', marginBottom: 16 }}
                            placeholder='Select Kiosk'
                            onChange={handleKioskChange}
                            value={selectedKiosks}
                        >
                            <Option value='ALL'>All Kiosks</Option>
                            {kiosks.map((kiosk) => (
                                <Option key={kiosk.id} value={kiosk.id}>
                                    {kiosk.screen_name}
                                </Option>
                            ))}
                        </Select>
                        <RangePicker
                            style={{ width: '100%', marginBottom: 16 }}
                            onChange={(dates) => setDateRange(dates)}
                            value={dateRange}
                            presets={rangePresets}
                            format={'DD-MM-YYYY'}
                            allowClear={false}
                        />
                        <Button
                            type='primary'
                            onClick={resetAnalytics}
                            block
                            style={{ marginBottom: 16 }}
                        >
                            Reset
                        </Button>
                    </Card>
                </Col>
                <Col span={20}>
                    {loading ? (
                        <Spin tip='Loading...' />
                    ) : (
                        <>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <RevenueComparison
                                        analyticsData={analyticsData}
                                        revenueCompare={revenueCompare}
                                        onComparisonChange={setRevenueCompare}
                                        selectedKiosks={selectedKiosks}
                                        selectedProperties={selectedProperties}
                                        selectedOutlets={selectedOutlets}
                                    />
                                </Col>
                            </Row>
                            <Card>
                                <Row gutter={16} style={{ margin: '0.1rem' }}>
                                    <Col span={12}>
                                        <TopSellingItem
                                            analyticsData={analyticsData}
                                            maxCount={maxCount}
                                            setMaxCount={setMaxCount}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <LeastSellingItem
                                            analyticsData={analyticsData}
                                            maxCount={maxCount}
                                            setMaxCount={setMaxCount}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    gutter={16}
                                    style={{
                                        margin: '1rem 0.1rem 0rem 0.1rem',
                                    }}
                                >
                                    <Col span={24}>
                                        <ItemWiseRevenue
                                            analyticsData={analyticsData}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default AnalyticsDashboard;
