import React from 'react';
import Logo from '../assets/vserveCinemaLogo.png';

const Loading = (props) => {
    const { title, isLogo } = props;
    return (
        <div className='loading-wrapper'>
            {isLogo && <img src={Logo} alt='loader-logo' />}
            {title || 'Loading...'}
        </div>
    );
};

export default Loading;
